/* eslint-disable @next/next/no-img-element */
import React, { useContext, useState } from 'react';
import { Col, Radio, Row } from 'antd';
import { useRouter } from 'next/router';
// import { useTranslation } from 'next-i18next';
import * as _ from 'lodash';
import * as Styled from './welcome.styled';
import { PageDataContext } from '~/context';
// import { formatNumber } from './welcome.utils';
import { link as linkUtils } from '~/utils';
import { useIsSmallScreen } from '~/hooks';
import Anchor from '~/components/Anchor';

const Welcome = () => {
  const router = useRouter();
  const { data } = useContext(PageDataContext);
  const [activeImage, setActiveImage] = useState(false);
  const { isSmallScreen, breakpoint } = useIsSmallScreen();
  const onClick = (link) => (e) => {
    e.preventDefault();
    linkUtils.handleRedirect(router, link);
  };
  const navsInfo = _.get(data, 'welcome');
  return (
    <Styled.Container isSmallScreen={isSmallScreen} id="welcome">
      <Styled.Content>
        <Row gutter={[32, 32]} justify="center">
          <Col span={isSmallScreen ? 24 : 18}>
            <Styled.Title>{navsInfo.title}</Styled.Title>
            <Styled.Intro>{navsInfo.intro}</Styled.Intro>
            <Styled.Navs>
              <Radio.Button
                onClick={(e) => {
                  setActiveImage(true);
                  setTimeout(() => {
                    setActiveImage(false);
                  }, 1000);
                  onClick('/#welcome')(e);
                }}
              >
                {navsInfo.join}
              </Radio.Button>
              <Radio.Button onClick={onClick(`mailto:${navsInfo.email}`)}>{navsInfo.contactUs}</Radio.Button>
            </Styled.Navs>
            <Styled.Code>
              <img
                style={
                  activeImage
                    ? { boxShadow: '0px 0px 30px #a4a4a4', transition: '0.5s all', width: 150, height: 150 }
                    : {
                        transition: '1.5s all',
                        width: 150,
                        height: 150,
                      }
                }
                src={`${process.env.NEXT_PUBLIC_CDN_URL}/images/code.jpg`}
                alt="请联系我"
              />
            </Styled.Code>
            <Styled.Numbers gutter={32} justify="center">
              {navsInfo.githubInfo.map(({ name, value }, idx) => (
                <Col key={idx} span={isSmallScreen ? 12 : 6}>
                  <em>{value}</em>
                  <span>{name}</span>
                </Col>
              ))}
            </Styled.Numbers>
          </Col>
        </Row>
      </Styled.Content>

      <Styled.HowTo isSmallScreen={!breakpoint.lg}>
        <Styled.Content>
          <ul>
            {navsInfo.howto.map((it) => {
              return (
                <li key={it.link}>
                  <Anchor href={it.link}>{it.title}</Anchor>
                </li>
              );
            })}
          </ul>
        </Styled.Content>
      </Styled.HowTo>
    </Styled.Container>
  );
};

export default Welcome;
