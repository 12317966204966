import React, { useContext, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { useRouter } from 'next/router';
import { GithubOutlined } from '@ant-design/icons';
import { useMount } from 'ahooks';
import { useTranslation } from 'next-i18next';
import * as _ from 'lodash'
import * as Styled from './banner.styled';
import * as NewStyled from './newbanner.styled';

// import ActivityIcon from './activity.svg';
// import ArticleIcon from './article.svg';
// import AsktugIcon from './asktug.svg';
// import DocIcon from './doc.svg';
import { PageDataContext } from '~/context';
import { common as commonUtils, link as linkUtils } from '~/utils';
import { getOwnMedia } from '~/pages/home/home.utils';
import { useIsSmallScreen } from '~/hooks';
import Anchor from '~/components/Anchor';


const Banner = () => {
  const tooltipContainerRef = useRef(null);
  const [mounted, setMounted] = useState(false);
  const router = useRouter();
  const { t } = useTranslation('page-home');
  const { data } = useContext(PageDataContext);
  const { isSmallScreen, breakpoint } = useIsSmallScreen();

  useMount(() => {
    setMounted(true);
  });


  const onClick = (link) => (e) => {
    e.preventDefault();
    linkUtils.handleRedirect(router, link);
  };

  return (
    <Styled.Container>
      <Styled.Content isSmallScreen={isSmallScreen}>
        <Row gutter={!isSmallScreen && [32, 64]} justify={isSmallScreen ? 'center' : 'space-between'} align="middle">
          <Styled.LeftPanel>
            <Styled.Logo />
            <Styled.Intro>{_.get(data, 'banner.intro', '')}</Styled.Intro>
            <Row gutter={32} justify={isSmallScreen ? 'space-around' : 'space-between'} align="bottom">
              <Col flex="auto">
                <Anchor href={_.get(data, 'banner.btnFirst.link', '')}>
                  <Styled.TryButton>{_.get(data, 'banner.btnFirst.title', '')}</Styled.TryButton>
                </Anchor>
              </Col>
              <Col flex="auto">
                <Anchor onClick={(e) => onClick(_.get(data, 'banner.btnSecond.link', ''))(e)}>
                  <Styled.GoButton>{_.get(data, 'banner.btnSecond.title', '')}</Styled.GoButton>
                </Anchor>
              </Col>
            </Row>
          </Styled.LeftPanel>

          <Styled.RightPanel>
            <Styled.Carousel isSmallScreen={isSmallScreen}>
              {_.get(data, 'banner.promotions', []).map(({ id, title, link, image }) => {
                const imgSrc = getOwnMedia(image.url);
                const props = {
                  title,
                  link,
                  image: imgSrc || 'https://img3.pingcap.com/uploads/_f0f390adfe.jpg',
                  height: (() => {
                    if (breakpoint.lg) {
                      return 250;
                    }
                    if (breakpoint.md) {
                      return 200;
                    }
                    if (breakpoint.sm) {
                      return 250;
                    }
                    if (breakpoint.xs) {
                      return 200;
                    }
                  })(),
                };

                return (
                  <Anchor key={id} href={link}>
                    <Styled.Promotion {...props}>
                      <Styled.PromotionOverlay>{title}</Styled.PromotionOverlay>
                    </Styled.Promotion>
                  </Anchor>
                );
              })}
            </Styled.Carousel>
          </Styled.RightPanel>
        </Row>

        {/* <Styled.Navs $isSmallScreen={isSmallScreen}>
          {navItems.map(({ icon: Icon, langKey }) => (
            <Col span={6} key={langKey}>
              <Styled.NavItem href={navsLang[langKey].link}>
                <Icon />
                <span>{navsLang[langKey].label}</span>
              </Styled.NavItem>
            </Col>
          ))}
        </Styled.Navs> */}
      </Styled.Content>
    </Styled.Container>
  );
};

export default Banner;
