import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'next-i18next';
import _get from 'lodash/get';
import * as Styled from './others.styled';
import TwoColumnsSection from '~/layouts/twoColumnsSection';
import VideoThumbnail from './videoThumbnail';
import { PageDataContext } from '~/context';
import { getImage, getOwnMedia } from '~/pages/home/home.utils';
import { useIsSmallScreen } from '~/hooks';

// const jobLogos = ['zhihu', 'xiaomi', 'iqiyi', 'ucloud', 'bilibili', 'pingcap'].map((el) =>
//   getImage(`others-${el}-logo.png`)
// );

const Others = () => {
  const { data } = useContext(PageDataContext);
  const { isSmallScreen } = useIsSmallScreen();
  // const { t } = useTranslation('page-home');

  // const lang = t('others', { returnObjects: true });
  // const {
  //   jobs: jobsLang,
  //   businessSupport: supportLang,
  //   communityCollaboration: collaborationLang,
  //   contact: contactLang,
  // } = lang;
  const learningdata = data.learning;
  return (
    <Styled.Container $isSmallScreen={isSmallScreen}>
      <TwoColumnsSection
        title={data.others.title}
        leftPanel={
          <>
            <Styled.ModuleTitle>
              <Styled.Subtitle>{data.others.areaTitle}</Styled.Subtitle>
              <Styled.Link href={data.others.moreLink}>{data.others.moreText}</Styled.Link>
            </Styled.ModuleTitle>
            <Row gutter={16}>
              {data.others.videos.slice(0, isSmallScreen ? 3 : 6).map((video, idx) => {
                const coverUrl = _get(video, ['cover', 'url'], '');
                const videoInfo = {
                  ...video,
                  coverImage: getOwnMedia(coverUrl),
                };
                return (
                  <Col key={idx} xs={24} md={8}>
                    <VideoThumbnail {...videoInfo} $isSmallScreen={isSmallScreen} />
                  </Col>
                );
              })}
            </Row>
          </>
        }
        rightPanel={
          <>
            {learningdata.relatedLinks.map((item, idx) => (
              <div key={idx}>
                <Styled.ModuleTitle>{item.name}</Styled.ModuleTitle>
                <Styled.LinksRow gutter={32}>
                  {item.sections.map((link, linkIdx) => (
                    <Styled.LinkWrapper key={linkIdx} flex={'none'}>
                      <Styled.Link href={link.link}>{link.title}</Styled.Link>
                    </Styled.LinkWrapper>
                  ))}
                </Styled.LinksRow>
              </div>
            ))}
          </>
        }
      />
    </Styled.Container>
  );
};

export default Others;
