/* eslint-disable react/jsx-pascal-case */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useTranslation } from 'next-i18next';
import * as Styled from './contribution.styled';
// import TwoColumnsSection from '~/layouts/twoColumnsSection';
import { getImage as getHomeImage, getOwnMedia } from '~/pages/home/home.utils';
import { useIsSmallScreen } from '~/hooks';
// import './index.css';
import { getLatestLearning, getBanner, getFiles } from '~/pages/home/learning/api';
import * as _ from 'lodash';
import avators from './avators.json'
import Anchor from '~/components/Anchor';
import { times } from 'lodash';

// const getImage = (name) => getHomeImage(`contribution-${name}`);

const Contribution = () => {
  const { t } = useTranslation('page-home');
  const { isSmallScreen } = useIsSmallScreen();

  // const lang = t('contribution', { returnObjects: true });
  // const { guide: guideLang, fork: forkLang } = lang;

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }
  const randomTrans = () => ({
    transform: `translate(${getRandomArbitrary(0, 20)}px, ${getRandomArbitrary(-10, 20)}px) scale(${getRandomArbitrary(
      0.5,
      1.2
    )})`,
  });

  const imgsArr = avators.map(it => it.url);
  const contrbutersData = imgsArr.map((img, idx, self) => {
    let size = Math.random() * (25 - -30) + -20 + 160;
    if (img.includes('svg')) {
      size = -30;
    }
    if (idx === Math.floor(self.length / 2)) {
      return (
        <Styled.centerPanel key={idx}>
          <Styled.centerPanelTitle>我们的贡献者们</Styled.centerPanelTitle>
          <Styled.centerPanelDes>Appsmith，是在这些开发人员的共同努力下，打造出来的作品。</Styled.centerPanelDes>
        </Styled.centerPanel>
      );
    }
    return (
      <img
        key={`$${idx}_img`}
        style={randomTrans()}
        src={getOwnMedia(img)}
        loading="lazy"
        width={`${size}`}
        alt="Profile Picture"
      />
    );
  });

  return (
    <Styled.Container id="contribution" isSmallScreen={isSmallScreen}>
      <Styled.imagesContainer id="canvas_panel">{contrbutersData}</Styled.imagesContainer>
    </Styled.Container>
  );
};

export default Contribution;
