/* eslint-disable @next/next/no-img-element */
import * as yup from 'yup';
import React, { useState, useContext } from 'react';
import { PageDataContext } from '~/context';
import _get from 'lodash/get'
import * as Styled from './subscription.styled';
import { useIsSmallScreen } from '~/hooks';


const Subscription = () => {
  const { isSmallScreen } = useIsSmallScreen();
  const { data } = useContext(PageDataContext);

  return (
    <Styled.Container>
      <Styled.TwoColumns
        isSmallScreen={isSmallScreen}
        leftPanel={
          <>
            <Styled.Slogan>
              {_get(data, 'others.tosay')}
              {/* <Styled.Link href={contributorListLang.link}>{contributorListLang.label}</Styled.Link> */}
            </Styled.Slogan>
          </>
        }
        rightPanel={
          <>
            <Styled.Img>
              <div>
                <img
                  style={{ width: 150, height: 150 }}
                  src={`${process.env.NEXT_PUBLIC_CDN_URL}/images/code.jpg`}
                  alt="请联系我"
                />
              </div>
            </Styled.Img>
          </>
        }
      />
    </Styled.Container>
  );
};

export default Subscription;
