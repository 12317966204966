import styled, { css } from 'styled-components';
import { colors, mixins, Styled } from '@tidb-community/ui';

import Anchor from '~/components/Anchor';

const { ModuleTitle, Section, Text } = Styled;

export { ModuleTitle, Section, Text };

export const Container = styled(Section)`
  background: #f7f8f9;
  padding: 1rem 0;
  max-height: 560px;
  overflow: hidden;
  position: relative;
`;

export const Link = styled(Anchor)`
  font-size: revert;
  text-decoration: underline;
  &:hover {
    color: ${colors.B1};
    text-decoration: underline;
  }
`;

export const StepBox = styled.div`
  margin-top: 2.5rem;
  ${(props) =>
    props.isSmallScreen &&
    css`
      margin-bottom: 2rem;
    `}
`;

export const StepHeader = styled.div`
  font-size: 18px;
  margin-bottom: 1rem;
`;
export const imagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 20px;
  overflow: hidden;
  padding-bottom: 40px;
  background: #f7f8f9;
`;
export const centerPanel = styled.div`
  width: 439px;
  height: 420px;
  background: #ffffffeb;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 100;
  padding: 68px;
  box-shadow: 0px 0px 50px #333333;
  -webkit-box-shadow: 0px 0px 50px #333333;
  box-shadow: 0px 0px 60px #33333317;
`;

export const centerPanelTitle = styled.div`
  text-align: center;
  margin-bottom: 32px;
  color: #2a2f3d;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.02em;
`;

export const centerPanelDes = styled.div`
  text-align: center;
  margin-bottom: 32px;
  color: #2a2f3d;
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: -0.02em;
`;

const iconSize = '38px';

export const StepIconWrapper = styled.div`
  ${mixins.size(iconSize)}
`;

export const StepIcon = styled.img`
  height: ${iconSize};
`;

export const EngageCallBox = styled.div`
  margin-top: 2rem;
`;

export const IssueList = styled.img`
  max-width: 100%;
  border-radius: 6px;
  margin-bottom: 2.5rem;
  background-color: #181a24;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

export const ForkTitle = styled(ModuleTitle)`
  font-weight: bold !important;
`;

export const EngageTitle = styled(ModuleTitle)`
  line-height: 1.7;
  font-size: 16px;
`;
