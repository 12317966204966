import styled, { css } from 'styled-components';
import { Col, Row } from 'antd';
import { colors, mixins, Styled } from '@tidb-community/ui';

const { ModuleTitle, Text } = Styled;

export { ModuleTitle, Text };

export const Container = styled(Row).attrs((props) => ({
  gutter: !props.$isSmallScreen && 64,
}))`
  // padding: 1rem 0;
  border-bottom: 1px solid ${colors.T2};
  margin: 0 !important;

  h3 {
    font-weight: normal;
    margin-bottom: 0;
  }

  &:hover {
    border-color: ${colors.B1};

    h3 {
      color: ${colors.B1};
    }
  }
  
  &:first-child {
    padding-top: 0;
  }
`;

export const ImageWrapper = styled(Col).attrs({
  xs: 24,
  md: 24,
})`
  overflow: hidden;
  height: 350px;
  box-shadow: 1px 2px -2px #0000001f;
  transition: box-shadow .3s,border-color .3s;
  &:hover {
    box-shadow:0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;
  }
`;

export const Content = styled(Col).attrs({
  xs: 0,
  md: 0,
})`
  ${(props) =>
    props.isSmallScreen &&
    css`
      padding-top: 1rem;
    `}
`;

export const Metadata = styled.h3`
  margin-bottom: 2rem;
  padding-bottom: 1rem;
`;

export const Intro = styled(Text)`
  padding: 15px 0px;
  text-indent:1em;
  min-height: 120px;
`;
