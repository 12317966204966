/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import jsConvert from 'js-convert-case';
import { api } from '@tidb-community/datasource';
import qs from 'querystring';
import Activities from './activities';
import Banner from './banner';
import Blogs from './blogs';
import Contribution from './contribution';
import Forum from './forum';
import Learning from './learning';
import Others from './others';
import Subscription from './subscription';
import Welcome from './welcome';
import Intro from './introduction';
import { CommunityHead } from '~/components';
import { CoreLayout } from '~/layouts';
import { PageDataContext } from '~/context';
import { getI18nProps } from '~/utils/i18n.utils';
import { getLatestBlog } from '~/pages/home/blogs/api';
import { getLatestLearning, getBanner, getWelcome } from '~/pages/home/learning/api';
import { getOtherContent } from '~/pages/home/others/api';

// FIXME: temporily rollback ISR back to SSR due to a CI build error
// We need to fix the circular dependencies issue with i18n locales
// const TEN_MINS = 10 * 60;

export const getServerSideProps = async (ctx) => {
  const client = await api.initStrapiClient();
  // const isProd = process.env.NEXT_PUBLIC_RUNTIME_ENV === 'production';

  // const strapiQuery = {
  //   params: {
  //     _publicationState: isProd ? undefined : 'preview',
  //   },
  // };
  const _github_info = {
    prNum: 109,
    topicNum: 18400,
    postNum: 397,
    contributorNum: 192,
  };

  const _forum_posts = [
    {
      title: '这是一篇论坛帖w子',
      link: 'http://bilibili.com',
      creator: {
        username: 'admin',
        avatarUrl: 'https://asktug.com/letter_avatar_proxy/v4/letter/t/4e50e8/90.png',
      },
      categories: [],
      postNum: 12,
      createdAt: `${new Date()}`,
      lang: {
        relay: 23,
      },
      isSmallScreen: false,
    },
  ];

  const _promotions = await getBanner();

  const _blogs = await getLatestBlog();
  const _learningContent = await getLatestLearning();
  const _welcome = await getWelcome()
  const _otherContent = await getOtherContent();

  const data = [];

  const i18nProps = await getI18nProps(['common', 'page-home'])(ctx);
  const _data = jsConvert.camelKeys(
    {
      githubInfo: _github_info,
      forumPosts: _forum_posts,
      blogs: _blogs,
      banner: _promotions,
      learning: _learningContent,
      others: _otherContent,
      welcome:_welcome,
    },
    {
      recursive: true,
      recursiveInArray: true,
    }
  );

  return {
    props: {
      ...i18nProps,
      data: _data,
    },
    // revalidate: TEN_MINS,
  };
};

// console.log(query, 'query')

const Page = ({ data }) => (
  <PageDataContext.Provider value={{ data }}>
    <CommunityHead />

    <CoreLayout>
      <Banner />
      <Welcome />
      <Intro />
      {/* 问答论坛 */}
      {/* <Forum /> */}
      {/* 活动 */}
      <Activities />
      {/* 专栏文章 */}
      <Blogs />
      
      {/* 学习 */}
      {/* <Learning /> */}
      {/* 贡献 */}
      <Others />
      <Contribution />
      {/* 其他资源 */}
      <Subscription />
    </CoreLayout>
  </PageDataContext.Provider>
);

export default Page;
