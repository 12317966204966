import React, { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { Row, Col, Divider, Button } from 'antd';
import * as Styled from './activities.styled';
import Activity from './contentPanel';
import * as _ from 'lodash';
import Meetup from './meetup';
import TwoColumnsSection from '~/layouts/twoColumnsSection';
import { Link } from '~/components';
import { PageDataContext } from '~/context';
import { useIsSmallScreen } from '~/hooks';
import { getOwnMedia } from '~/pages/home/home.utils';

// const Meetups = ({ meetups }) => (
//   <>
//     {meetups.map((item, idx) => {
//       const props = {
//         key: idx,
//         ...item,
//       };
//       return <Meetup {...props} />;
//     })}
//   </>
// );

const Activities = () => {
  const { data } = useContext(PageDataContext);
  const { isSmallScreen } = useIsSmallScreen();
  // const { t } = useTranslation('page-home');
  const { others } = data;

  // const lang = t('activities', { returnObjects: true });
  // const viewAllLang = t('common:viewAll');

  // const demoGallery = [
  //   {
  //     link:'https://lowcode.methodot.com/applications/62a1aae112168d652db26a3b/pages/62a1aae112168d652db26a47',
  //     title: '电商小程序',
  //     coverUrl: getOwnMedia('/uploads/weapp.gif'),
  //     staticCover: getOwnMedia('/uploads/weapppic.png'),
  //     showcode: getOwnMedia('/uploads/weapp_demo.png'),
  //     desc: 'PagePlug 制作的完整电商小程序案例，支持注册、登录、二级商品分类、商品搜索、商品详情、购物车、购买下单、收货地址管理、订单管理等功能。后端接口使用开源后台 litmall 的一个部署实例（实例来自 flutter_mall）'
  //   },
  //   {
  //     link:'https://lowcode.methodot.com/applications/62a1aae112168d652db26a3b/pages/62a1aae112168d652db26a47',
  //     title: '工程管理系统（PC web应用）',
  //     coverUrl: getOwnMedia('/uploads/menu.gif'),
  //     staticCover: getOwnMedia('/uploads/pcpic.png'),
  //     desc: "PagePlug 制作的一个典型CRUD项目，实现了基本增删改查、后端分页、搜索过滤、弹窗表单、复杂表单等功能"
  //   }
  // ]

  const demoGallery = [
    {
      link: 'https://lowcode.methodot.com/applications/62a1aae112168d652db26a3b/pages/62a1aae112168d652db26a47',
      title: '电商小程序',
      coverUrl: '/uploads/weapp.gif',
      staticCover: '/uploads/weapppic.png',
      showcode: '/uploads/weapp_demo.png',
      desc: 'PagePlug 制作的完整电商小程序案例，支持注册、登录、二级商品分类、商品搜索、商品详情、购物车、购买下单、收货地址管理、订单管理等功能。后端接口使用开源后台 litmall 的一个部署实例（实例来自 flutter_mall）',
    },
    {
      link: 'https://lowcode.methodot.com/applications/62a1aae112168d652db26a3b/pages/62a1aae112168d652db26a47',
      title: '工程管理系统（PC web应用）',
      coverUrl: '/uploads/menu.gif',
      staticCover: '/uploads/pcpic.png',
      desc: 'PagePlug 制作的一个典型CRUD项目，实现了基本增删改查、后端分页、搜索过滤、弹窗表单、复杂表单等功能',
    },
  ];

  const demo = {
    demoGallery: _.get(others, 'pageplug.demoGallery', demoGallery),
    preview: _.get(others, 'pageplug.preview', 'https://lowcode.methodot.com/'),
  };

  return (
    <Styled.Container isSmallScreen={isSmallScreen}>
      <Styled.Panel>
        <Styled.TitleH2>在线模板</Styled.TitleH2>
        <Styled.DividerS />

        <Row justify="space-around" wrap={true}>
          <Col span={11}>
            <Styled.ActivitiesBox isSmallScreen={isSmallScreen}>
              <Activity data={demo.demoGallery[0]} isSmallScreen={isSmallScreen} />
            </Styled.ActivitiesBox>
          </Col>
          <Divider type="vertical" />
          <Col span={11}>
            <Styled.ActivitiesBox isSmallScreen={isSmallScreen}>
              <Activity data={demo.demoGallery[1]} isSmallScreen={isSmallScreen} />
            </Styled.ActivitiesBox>
          </Col>
        </Row>
      </Styled.Panel>
      <Row justify="center" style={{ marginTop: 20 }}>
        <h1>
          👻进入
          <Button type="link" size="large" href={demo.preview}>
            pageplug 线上体验
          </Button>
          ！导入模板应用JSON，即可开启您的低代码之旅！
        </h1>
      </Row>
    </Styled.Container>
  );
};
export default Activities;
