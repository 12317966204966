/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @next/next/no-img-element */
import React, { useContext, useState } from 'react';
import { Col, Radio, Row } from 'antd';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import * as Styled from './welcome.styled';
import { PageDataContext } from '~/context';
import { formatNumber } from './welcome.utils';
import { link as linkUtils } from '~/utils';
import { useIsSmallScreen } from '~/hooks';
import Anchor from '~/components/Anchor';

import GitHubIcon from './github.svg';
import GiteeIcon from './logo-black.svg';
import _ from 'lodash';

const Intro = () => {
  const router = useRouter();
  const { data } = useContext(PageDataContext);
  const { isSmallScreen, breakpoint } = useIsSmallScreen();

  const { others } = data;

  return (
    <Styled.Container isSmallScreen={isSmallScreen} id="introduction">
      <Styled.Content>
        <Row gutter={[32, 32]} justify="center">
          <Col span={isSmallScreen ? 24 : 18}>
            <Styled.Title>{_.get(others, 'pageplug.title')}</Styled.Title>
            <Styled.Intro>{_.get(others, 'pageplug.desc')}</Styled.Intro>
          </Col>
        </Row>
        <Row gutter={24} justify="center" style={{ marginBottom: 32 }}>
          <Col>
            <Anchor href={`https://github.com/cloudtogo/pageplug`}>
              <Styled.GitHub>
                <GitHubIcon />
              </Styled.GitHub>
            </Anchor>
          </Col>
          <Col>
            <Anchor href={_.get(others, 'pageplug.preview', 'https://lowcode.methodot.com/')}>
              <Styled.GoButton>立即体验!</Styled.GoButton>
            </Anchor>
          </Col>
          <Col>
            <Anchor href={'https://gitee.com/cloudtogo/pageplug'}>
              <Styled.GitHub>
                <GiteeIcon />
              </Styled.GitHub>
            </Anchor>
          </Col>
          <Col>
            <Anchor
              href={_.get(
                others,
                'pageplug.deploy',
                'https://lowcode.methodot.com/app/pageplug/page1-63160074cb370d532de7f2af?embed=1'
              )}
            >
              <Styled.GoButton>私有化部署</Styled.GoButton>
            </Anchor>
          </Col>
        </Row>
        <div>
          <iframe
            style={{ width: '100%', height: 500 }}
            src={_.get(others, 'pageplug.videoiframe')}
            scrolling="no"
            border="0"
            frameBorder="no"
            frameSpacing="0"
            allowFullScreen="true"
          ></iframe>
        </div>
      </Styled.Content>
    </Styled.Container>
  );
};

export default Intro;
