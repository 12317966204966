import React, { useContext } from 'react';
import { Col } from 'antd';
import { Trans, useTranslation } from 'next-i18next';
import * as Styled from './learning.styled';
import _get from 'lodash/get';
import TwoColumnsSection from '~/layouts/twoColumnsSection';
import { getImage, getOwnMedia } from '~/pages/home/home.utils';
import { useIsSmallScreen } from '~/hooks';
import Anchor from '~/components/Anchor';
import { PageDataContext } from '~/context';

const Learning = () => {
  const { data } = useContext(PageDataContext);
  const { isSmallScreen } = useIsSmallScreen();
  const { t } = useTranslation('page-home');

  const lang = data.learning;
  const { majorVideo: _majorVideoLang } = lang;
  const majorVideoLang = _get(_majorVideoLang, ['data', 'attributes'], {});
  const majorVideoCover = _get(_majorVideoLang, ['data', 'attributes', 'coverUrl', 'data', 'attributes'], {});
  const majorVideoLink = _get(_majorVideoLang, ['data', 'attributes', 'link', 'data', 'attributes'], {});
  // debugger
  return (
    <Styled.Container id="learning" isSmallScreen={isSmallScreen}>
      <TwoColumnsSection
        title={lang.title}
        leftPanel={
          <>
            {/* <Styled.Logo src={getOwnMedia('/uploads/logo_babed9136f.svg')} /> */}

            <Styled.Text>
              {/* <Trans
                t={t}
                i18nKey={'learning.desc'}
                components={[<Styled.Link href={lang.educationLink} />, <Styled.Link href={lang.certificateLink} />]}
              /> */}
            </Styled.Text>

            <Styled.VideoHeader>
              <Styled.TitleLink href={getOwnMedia(majorVideoLink.url)}>{lang.videosTitle}</Styled.TitleLink>
            </Styled.VideoHeader>

            <Styled.VideosRow wrap={false}>
              <Col xs={24} md={18}>
                <Styled.VideoBoxWrapper $isSmallScreen={isSmallScreen}>
                  <Styled.VideoBox $isSmallScreen={isSmallScreen} src={getOwnMedia(majorVideoCover.url)}>
                    <Styled.VideoPlayButton>
                      <Anchor href={getOwnMedia(majorVideoLink.url)}>
                        <Styled.VideoPlayIcon color="white" height="48px" />
                      </Anchor>
                    </Styled.VideoPlayButton>
                    {!isSmallScreen && (
                      <Styled.VideoCaption isSmallScreen={isSmallScreen}>{majorVideoLang.desc}</Styled.VideoCaption>
                    )}
                  </Styled.VideoBox>
                </Styled.VideoBoxWrapper>
              </Col>
              <Col xs={0} md={6}>
                {lang.minorVideos.map((video, idx) => {
                  const coverUrl = _get(video, ['coverUrl', 'data', 'attributes', 'url'], '');
                  return (
                    <Styled.VideoBoxWrapperSmall key={idx}>
                      <Styled.VideoBox $isSmallScreen={isSmallScreen} src={getOwnMedia(coverUrl)}>
                        <Styled.VideoOverlay href={video.link}>
                          <Styled.VideoOverlayText>{video.title}</Styled.VideoOverlayText>
                        </Styled.VideoOverlay>
                      </Styled.VideoBox>
                    </Styled.VideoBoxWrapperSmall>
                  );
                })}
              </Col>
            </Styled.VideosRow>

            {isSmallScreen && <Styled.Text>{majorVideoLang.desc}</Styled.Text>}

            <Styled.More href={lang.moreLink} $isSmallScreen={isSmallScreen}>
              {lang.more}
            </Styled.More>
          </>
        }
        rightPanel={
          <>
            {lang.linkSections.map((section, idx) => (
              <div key={idx}>
                <Styled.ModuleTitle>{section.title}</Styled.ModuleTitle>

                <Styled.LinksRow gutter={32}>
                  {section.links.map((link, linkIdx) => (
                    <Styled.LinkWrapper key={linkIdx} flex={'none'}>
                      <Styled.Link href={link.link}>{link.label}</Styled.Link>
                    </Styled.LinkWrapper>
                  ))}
                </Styled.LinksRow>
              </div>
            ))}
          </>
        }
      />
    </Styled.Container>
  );
};

export default Learning;
