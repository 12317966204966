import Image from 'next/image';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { EyeOutlined } from '@ant-design/icons';
import * as Styled from './activity.styled';
import { common as commonUtils } from '~/utils';
import { Tooltip, Row, Col, Button, Popover, Image as AImage } from 'antd';
import { getOwnMedia } from '~/pages/home/home.utils';

import Anchor from '~/components/Anchor';

const Activity = ({ data, isSmallScreen }) => {
  const props = {
    $isSmallScreen: isSmallScreen,
  };

  const [hover, setHover] = useState(false);

  // const imgProps = commonUtils.getStrapiImgProps(image);

  return (
    <Styled.Container {...props}>
      <Styled.Metadata>
        {data.title}
        {data.showcode ? (
          <Popover
            content={<AImage preview={false} src={getOwnMedia(data.showcode)} width={135} />}
            title="严选小程序（微信）"
          >
            <EyeOutlined style={{ position: 'absolute', cursor: 'pointer', right: 2, padding: '0px 10px' }} />
          </Popover>
        ) : (
          <Anchor href={data.link}>
            <Tooltip title="效果预览">
              <EyeOutlined style={{ position: 'absolute', cursor: 'pointer', right: 2, padding: '0px 10px' }} />
            </Tooltip>
          </Anchor>
        )}
      </Styled.Metadata>
      <Styled.ImageWrapper onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Anchor href={'https://gitee.com/cloudtogo/pageplug/tree/open/demo'}>
          <Tooltip title="点击获取模板代码" color={'cyan'}>
            <Image
              unoptimized
              alt={data.title}
              src={hover ? getOwnMedia(data.coverUrl) : getOwnMedia(data.staticCover)}
              layout="fill"
              objectFit="cover"
            />
          </Tooltip>
        </Anchor>
      </Styled.ImageWrapper>
      <Styled.Content></Styled.Content>
      <Styled.Intro>{data.desc}</Styled.Intro>
    </Styled.Container>
  );
};

export default Activity;
